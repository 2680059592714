.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #feff5b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-nav {
  background-color: #36295e;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.borderAccordion::before {
  @apply border border-2 border-[#F7F7F7];
}


.primary-bg-color {
  background-color: #6840E0;
}

.secondary-bg-color {
  background-color: #F3EDF7;
}

.rounded-container {
  border-radius: 50%;
}